import { API } from 'aws-amplify';

import AWS from 'aws-sdk';
import { DBtable } from './Constants';
import React, { useState, useEffect, useRef } from 'react';


const updateDBitem = async (record ) => {
    const path = "scrum/update";
    console.log(`Executing API.post on https://prod.api.amazonavoc.com/${path}`)
    console.log(`Updating record : ${record}` ); //DEBUG AAM
    console.log(record); //DEBUG AAM
  
    var handleError = function (err) {
      console.warn(err);
      return new Response(JSON.stringify({
          code: 400,
          message: `Error updating item : ${record} `
      }));
    };
  
    const response = await (API.post(
      'Prod', 
      path, {
        response: true,
        body: {
          action : "update_record",
          item : record
        }
      }).catch(handleError));
    
      const data = await response;
      console.log(data) //DEBUG AAM
      return data;
  }

export {
    updateDBitem,
}