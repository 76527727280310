
// Ref: https://blog.csdn.net/weixin_47287832/article/details/124572802

import React, {useContext, useState, useEffect} from 'react';
import { Layout, Menu, Tooltip } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ToolOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons'
import { useLocation, useNavigate, Route, Routes } from 'react-router-dom'

import PointingToolManger from './PointingToolManger';
import ScrumTools from './ScrumTools';
import Login from './Login';
import { GlobalContext } from './contexts/ContextProvider';
import { updateDBitem  } from './DbUtils';

import './App.less';
import 'antd/dist/antd.min.css';

const { Content, Sider } = Layout;

const MenuSider = () => {
  const [ globalState, globalDispatch] = useContext(GlobalContext);
  const [collapsed, setCollapsed] = React.useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  let navigate = useNavigate();
  const selectedKey = useLocation().pathname
  // console.log(selectedKey);

  useEffect( () => {
    if (globalState.userName !== 'guest'){

      window.addEventListener("beforeunload", function(e){
        updateDBitem({
          userName:globalState.userName,
          vote: "0",
          reveal: false,
          joined: false,
      })
        window.location.href = 'https://www.scrum.amazonavoc.com/';
      }, false);
  
      window.addEventListener("onunload", function(e){
        updateDBitem({
          userName:globalState.userName,
          vote: "0",
          reveal: false,
          joined: false,
      })
        window.location.href = 'https://www.scrum.amazonavoc.com/';
      }, false);
      
    }
   
  },[globalState.userName]);

 

  const highlight = () => {
    if (selectedKey === '/'){
      return ['1']
    } else if (selectedKey === '/scrumtools'){
      return ['2']
    }  
  }


  return (
    <Layout className="site-layout">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo"> 
            <Tooltip placement="right" arrowPointAtCenter  title="Expand / Shrink Menu" >
                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: toggleCollapsed,
                })}
            </Tooltip>
          </div>
          <Menu
            mode="inline"
            theme="light"
            defaultSelectedKeys={['1']}
            selectedKeys={highlight()}
            style={{ height: '100%', borderRight:0 }}
            items={[
              {
                key: '1',
                icon: <ToolOutlined />,
                label: "Pointing Tool",
                onClick: () => { navigate('/')}
              },
              {
                key: '2',
                icon: <UserOutlined />,
                label: "Scrum Tool",
                onClick: () => { navigate('/scrumtools')}
              }

            ]}
            />
        </Sider>
        <Content>
          {/* {console.log("inside menusider")} */}
          <Routes>
            <Route exact path="/" element={<PointingToolManger />} />
            <Route path="/scrumtools" element={<ScrumTools />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Content>
    </Layout>
  )
}

export default MenuSider;
