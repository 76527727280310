import React, { useContext } from 'react';
import styled from 'styled-components';
import { Card, Button,Divider, Row, Col, Spin, Space} from 'antd';

import {  updateDBitem } from './DbUtils';
import { GlobalContext } from './contexts/ContextProvider';
import {guestDict, moderatorDict, buttonColors} from './Constants';
import { capitalize } from './utils';

import './App.css';

// ======================================================================================


const StyledHeader = styled.h1 `
  margin: 0 auto; 
  // margin-top: 1.0vw;
  margin-bottom: 2.0vw;
  text-align: center;
  font-size: 3.0vw;
  font-weight: 800;
  letter-spacing: 0.2rem;
  color: #e47911; /* Amazon Orange */
  // border: 0.1rem solid #f1f1f1;
`

function SelectUser() {
  const [ globalState, globalDispatch] = useContext(GlobalContext);
  // const buttonColors = ["#6b5b95", "#d64161", "#3e4444", "#405d27", "#50394c", "#36486b", "#667292", "#588c7e" ]

  const handleNameClick = (member) => {
    globalDispatch({type: 'SHOW_POINTING_PAGE', showPointingPage: true });
    globalDispatch({type: 'SET_USERNAME', userName: member });
    updateDBitem({userName: member, joined: true})

    // //Reset DB data after user closes browser window
    // window.addEventListener("onbeforeunload", function(e){
    //   e.preventDefault();
    //   alert('(1) Clearning user data in Scrum DB.');
    //   updateDBitem({
    //     userName:member,
    //     vote: "0",
    //     reveal: false,
    //     joined: false,
    //   })
    // }, false);

    // window.addEventListener("onunload", function(e){
    //   e.preventDefault();
    //   alert('(2) Clearning user data in Scrum DB.');
    //   updateDBitem({
    //     userName:member,
    //     vote: "0",
    //     reveal: false,
    //     joined: false,
    //   })
    // }, false);

  }
  

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col 
          className="gutter-row text-center"
          span={24}
          style={{  width: '97vw', height: '90vh', background: "#fff"}}
        >
        <Card bordered={false}
          style={{  margin: '0 auto', marginTop: '10vh'}} 
          title={<StyledHeader> Who are you ? </StyledHeader>}
        >
          <Space split={<Divider type="vertical" style={{ margin: '0 auto'}} />}>
          { console.log(globalState)}
            { globalState.ddbData === undefined || globalState.ddbData.length === 0 
            ? 
              <Spin size={'large'} />
            :

              // globalState.ddbData.filter( (data) => data.userName !== 'Moderator' && data.userName !== 'Guest').map((data,idx) => { 
              globalState.ddbData.filter( (data) => !(data.userName in moderatorDict) && !(data.userName in guestDict)).map((data,idx) => { 
                return <Button 
                      key={`${data['userName']}-${idx}`}
                      size="medium"
                      shape="round"
                      style={{ color: 'white', background: `${buttonColors[idx]} `}}
                      className={`m-2 `}
                      onClick={() => handleNameClick(data['userName'], globalState, globalDispatch)}
                  >
                  {capitalize(data['userName'])}
                  </Button>
                })
            }
          </Space>

          <Divider orientation="left" plain style={{borderTop: "none"}} ></Divider>

          <Space split={<Divider type="vertical" />}>
            { console.log(globalState)}
            { globalState.ddbData === undefined || globalState.ddbData.length === 0 
            ? 
              <Spin size={'large'} />
            :

              // globalState.ddbData.filter( (data) => data.userName === 'Moderator' || data.userName === 'Guest').map((
              globalState.ddbData.filter( (data) => (data.userName in moderatorDict) || (data.userName in guestDict)).map((
                data,idx) => { 
                return <Button 
                        key={`${data['userName']}-${idx}`}
                        size="medium"
                        shape="round"
                        style={{ color: 'white', background: `${buttonColors[idx]} `}}
                        className={`m-2 `}
                        onClick={() => handleNameClick(data['userName'], globalState, globalDispatch)}
                      >
                        {capitalize(data['userName'])}
                      </Button>
                  }
              )
            }
          </Space>
        </Card>
      </Col>
      <Col>
      </Col>
    </Row>
  );
}

export default SelectUser;
