import React, { useState } from 'react';
import { Alert,Button, Space } from 'antd';
import './coinToss.css';
import heads from './heads.png';
import tails from './tails.png';


const Coin = (props) => {
  // console.log(`Coin function props = ${props}`);
  // console.log(props);
  return (
    <img className='Coin-img' src={props.face} alt={`coin showing ${props.face}`}/> 
  )
}

const CoinToss = (props) => {
  let flipCoinInner = 'flip-coin-inner';
  const coinFace= [heads, tails]
  const [ tossDone, setTossDone] = useState(false);
  const [ flipAnimation, setFlipAnimation] = useState(false);
  const [state, setState ] = useState({
    frontFace: randomCoinFace(),
    backFace: randomCoinFace(),
    flips: 0, 
    heads: 0,
    tails: 0,
  }) 
  
     
  function randomCoinFace() {
    return coinFace[Math.floor(Math.random() * coinFace.length)];
  }  

  function toss() {
    const changeFace = randomCoinFace();
    setFlipAnimation(true)

    setTimeout(()=>{
        setState(st=> ({
            frontFace: changeFace === heads ? coinFace[0] : coinFace[1], 
            backFace: changeFace === heads ? coinFace[1] : coinFace[0], 
            heads: changeFace === heads ? st.heads + 1 : st.heads + 0, 
            tails: changeFace === tails ? st.tails + 1 : st.tails + 0, 
            flips: st.flips + 1, 
        }))
        // console.log(`result: ${state.frontFace}`)
    }, 50)

    setTimeout(()=> {
        setFlipAnimation(false);
    }, 500);

    setTossDone(true);
  }

    return (    
      <>
        {/* <div className="CoinToss"> */}
        <div>
            {/* <h1 style={{alignItems: "center", justifyContent: "center"}}>Coin Toss</h1> */}
            <div className='flip-coin'>
                <div className= {flipAnimation === true?  flipCoinInner += ' flip-animation' : flipCoinInner }>
                    <div className='flip-coin-front'>
                        <Coin face={state.frontFace} /> 
                    </div>
                    <div className='flip-coin-back'>
                        <Coin face={state.backFace} /> 
                    </div>
                </div>
            </div>
            <Button block type="primary"  className="ant-btn-primary" disabled={flipAnimation} onClick={toss}>
                {flipAnimation === false ? 'Toss it!' : 'Flipping...'}
            </Button>
            {/* <button onClick={reset}>Reset</button> */}
            { 
                tossDone 
                ? <>
                  <br /> <br />
                    <Alert 
                      // message={<Space size="small">Trials:{state.flips}  Heads:{state.heads}  Tails:{state.tails} </Space>} 
                      message={<Space align="center"> Result: {state.frontFace.includes("heads") ?  "Heads" :  "Tails"} </Space>} 
                      type="success"
                      closable
                      onClose={() => setTossDone(false)}
                      className="space-align-block"
                    /> 
                  </>
                : ""
              }
        </div>
      </>
      
    )
  
}

export default CoinToss;