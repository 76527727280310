import React from 'react';
import ReactDOM from 'react-dom/client';
import {Amplify} from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import {AuthContextProvider} from './contexts/AuthProvider';
import { GlobalContextProvider } from './contexts/ContextProvider';
import { prodConfig } from './config/Prodconfig';
import App from './App';


import './index.css';

Amplify.configure(prodConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthContextProvider>
    <GlobalContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </GlobalContextProvider>
  </AuthContextProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
