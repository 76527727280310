// holds constants used by the tool

const onCallList = [
  "brwilb",
  "lingbi",
  "jhhazen",
  "ammandra",
  "ahmedmst",
  "swatpat",
  "muraokad",
  "rratkie",
  "bweylin",
];

const onCallDict = {
  brandon: "brwilb",
  bai: "lingbi",
  jesse: "jhhazen",
  anil: "ammandra",
  ahmed: "ahmedmst",
  swati: "swatpat",
  jordyn: "muraokad",
  ryan: "rratkie",
  weylin: "bweylin",
};

const moderatorDict = { charlie: "cwcook", paul: "pfiore" };
const guestDict = { guest: "guest" };

//Make sure # elements in buttonColors >= # elements in cardSizes
const cardSizes = ["1", "2", "3", "5", "8", "13", "20", "40", "?"];
const buttonColors = [
  "#6b5b95",
  "#d64161",
  "#3e4444",
  "#405d27",
  "#50394c",
  "#36486b",
  "#667292",
  "#588c7e",
  "#FF5733",
  "#3399FF",
];

// const DBtable = "scrumDbTable";
const DBtable = "dv-live-scrum-db";

const pointingBase = {
  "1 Point": "Less than 1 day",
  "2 points": "1 Day",
  "3 points": "Day and a half",
  "5 points": "3 Days",
  "8 points": "Half a sprint",
  "13 points": "More than half of a sprint",
  "20 points": "1 Person, whole sprint",
  "40 points": "2 People, whole sprint",
  "60 points": "3 People, whole sprint",
};

const DbPollPeriod = 1000; // 1 seconds
// const DbPollPeriod = 2000 // 2 seconds

const scrumNameTable = [
"Rhydon",
"Kangaskhan",
"Nidoran♂",
"Clefairy",
"Spearow",
"Voltorb",
"Nidoking",
"Slowbro",
"Ivysaur",
"Exeggutor",
"Lickitung",
"Exeggcute",
"Grimer",
"Gengar",
"Nidoran♀",
"Nidoqueen",
"Cubone",
"Rhyhorn",
"Lapras",
"Arcanine",
"Mew",
"Gyarados",
"Shellder",
"Tentacool",
"Gastly",
"Scyther",
"Staryu",
"Blastoise",
"Pinsir",
"Tangela",
"Growlithe",
"Onix",
"Fearow",
"Pidgey",
"Slowpoke",
"Kadabra",
"Graveler",
"Chansey",
"Machoke",
"Hitmonlee",
"Hitmonchan",
"Arbok",
"Parasect",
"Psyduck",
"Drowzee",
"Golem",
"Magmar",
"Electabuzz",
"Magneton",
"Koffing",
"Mankey",
"Seel",
"Diglett",
"Tauros",
"Farfetch'd",
"Venonat",
"Dragonite",
"Doduo",
"Poliwag",
"Jynx",
"Moltres",
"Articuno",
"Zapdos",
"Ditto",
"Meowth",
"Krabby",
"Vulpix",
"Ninetales",
"Pikachu",
"Raichu",
"Dratini",
"Dragonair",
"Kabuto",
"Kabutops",
"Horsea",
"Seadra",
"Sandshrew",
"Sandslash",
"Omanyte",
"Omastar",
"Jigglypuff",
"Wigglytuff",
"Eevee",
"Flareon",
"Jolteon",
"Vaporeon",
"Machop",
"Zubat",
"Ekans",
"Paras",
"Poliwhirl",
"Poliwrath",
"Weedle",
"Kakuna",
"Beedrill",
"Dodrio",
"Primeape",
"Dugtrio",
"Venomoth",
"Dewgong",
"Caterpie",
"Metapod",
"Butterfree",
"Machamp",
"Golduck",
"Hypno",
"Golbat",
"Mewtwo",
"Snorlax",
"Magikarp",
"Muk",
"Kingler",
"Cloyster",
"Electrode",
"Clefable",
"Weezing",
"Persian",
"Marowak",
"Haunter",
"Abra",
"Alakazam",
"Pidgeotto",
"Pidgeot",
"Starmie",
"Bulbasaur",
"Venusaur",
"Tentacruel",
"Goldeen",
"Seaking",
"Ponyta",
"Rapidash",
"Rattata",
"Raticate",
"Nidorino",
"Nidorina",
"Geodude",
"Porygon",
"Aerodactyl",
"Magnemite",
"Charmander",
"Squirtle",
"Charmeleon",
"Wartortle",
"Charizard",
"Oddish",
"Gloom",
"Vileplume",
"Bellsprout",
"Weepinbell",
"Victreebel",
];

export {
  onCallList,
  moderatorDict,
  guestDict,
  onCallDict,
  cardSizes,
  DBtable,
  pointingBase,
  DbPollPeriod,
  scrumNameTable,
  buttonColors,
};
