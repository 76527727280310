//  Scrum tool manager component

import React, { useState, useEffect } from 'react';
import { Alert, Card, Divider, Descriptions, Space, Select, Row, Col, Form, Input, Button, Layout, Collapse, Typography } from 'antd';
import { EnterOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { onCallList, onCallDict, scrumNameTable } from './Constants';
import { Wheel } from 'react-custom-roulette';
import CointToss from './CoinToss';
import styled from 'styled-components';
import { capitalize } from './utils';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const { Panel } = Collapse;
const { Search } = Input;
const { Option } = Select;

const data = Object.keys(onCallDict).map(member => { return { option: member } });
const wheelOptionsData = Object.keys(onCallDict).map(member => {
    return <Option key={member}> {member} </Option>
});

const StyledDiv = styled.div`
    margin: 0 auto;
    background: #FFFF;
    width: 100vw;
    // padding: 8px 0;
    // border: 0.1rem solid red;
    // text-align: center;
`

const StyledLayout = styled(Layout)`
    margin: 0 auto;
    background: #FFFF;
    width: 100vw;
    // padding: 8px 0;
    // border: 0.1rem solid red;
    // text-align: center;
`


function ScrumTools() {
    const [form] = Form.useForm();
    const [sprintPoints, setSpringPoints] = useState(undefined);
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);
    
    const [shuffleList, setShuffleList] = useState(onCallList);
    const [shuffled, setShuffled] = useState(false);
    const [sprintIndex, setSprintIndex] = useState(0);
    const [wheelData, setWheelData] = useState([]);
    const [removeUsersList, setRemoveUsersList] = useState([]);
    const [showWheelOfFortune, setShowWheelOfFortune] = useState(false);
    const [wheelOfFortuneDropDownOpen, setWheelOfFortuneDropDownOpen] = useState(true);
    const [spinDuration, setSpinDuration] = useState(1.0);

    const onFinish = (values) => {
        console.log(values);
        // Formula: Number of Engineer x 20 point/engineer/sprint - (Engineer vacation days lost x 2) - (Public Holiday x number of engineer
         // Formula: (Number of Engineer x 2 point/engineer/day x numbder of engg days) - (Engineer vacation days lost x 2) - (Public Holiday x number of engineer
        let finalPoints = (values.totalEngineers * 2 * values.totalEngineeringDays) - (values.daysLost * 2) - (values.publicHolidays * values.totalEngineers);
        setSpringPoints(finalPoints);
        form.resetFields();
    };

    const handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * data.length)
        // setSpinDuration(Math.floor(1.0 + Math.random() * (2.0 + 1 - 1.0)))
        setSpinDuration(Math.floor(1.0 + Math.random() * (1.0 + 1 - 1.0)))
        setPrizeNumber(newPrizeNumber)
        setMustSpin(true)
    }

    const alertComponent = () => {
        return <Alert
            message={`Total Sprint Points : ${sprintPoints}`}
            type="info"
            closable
            onClose={() => setSpringPoints(undefined)}
        />
    }

    const onCardButtonClick = () => {
        const shuffledList = Object.keys(onCallDict).sort(() => {
            return Math.random() > 0.5 ? 1 : -1;
        })
        setShuffleList(shuffledList);
        setShuffled(true)
    }

    const onSprintNameSearch = (e) => {
        if (scrumNameTable.indexOf(capitalize(e)) >= 0) {
            setSprintIndex(scrumNameTable.indexOf(capitalize(e)));
        }
        else {
            // console.log("invalid search");
            setSprintIndex(0);
        }

    }

    const handleWheelOptionsSelect = (e) => {
        if (!(removeUsersList.includes(e))) {
            setRemoveUsersList(e)
        }
    }

    const handleRemoveUsersList = () => {
        let currentWheelData = Object.keys(onCallDict).filter(member => !removeUsersList.includes(member)).map( m => {
            return { option : m }
        })
        setWheelData(currentWheelData);
        setRemoveUsersList([]);
        setShowWheelOfFortune(true);
        setWheelOfFortuneDropDownOpen(false);
    }

    const handleDoNotRemoveUsers = () => {
        let currentWheelData = Object.keys(onCallDict).map( m => {
            return { option : m }
        })
        setWheelData(currentWheelData);
        setRemoveUsersList([]);
        setShowWheelOfFortune(true);
        setWheelOfFortuneDropDownOpen(false);
    }

    useEffect(() => {
        setShuffled(false)
    }, [sprintPoints, spinDuration]);

    return (
        <StyledLayout className="site-layout">
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ height: '50vh', margin: "5vh" }}>
                <Col className="gutter-row site-card-border-less-wrapper" span={5}>
                    <Card bordered={false} title="Sprint Master Rotation" >
                        {
                            onCallList.sort().map(member => {
                                return member !== 'bewlli' ? <p key={member} > {member} </p> : ""
                            })
                        }
                    </Card>
                </Col>
                <Col className="gutter-row site-card-border-less-wrapper" span={5}>
                    <Card bordered={false} title="Oncall Rotation" >
                        {
                            Object.keys(onCallDict
                            ).map(key => {
                                return <p key={key}> {onCallDict[key]} </p>
                            })
                        }
                    </Card>
                </Col>
                <>

                    <Col className="gutter-row site-card-border-less-wrapper" span={4}>
                        <Card
                            bordered={false}
                            title="Name Randomizer"
                            extra={<EnterOutlined onClick={onCardButtonClick} />}
                        >

                            {
                                shuffled ?
                                    shuffleList.map(member => {
                                        return <p key={member}> {onCallDict[member]} </p>
                                    })
                                    : Object.keys(onCallDict
                                    ).map(key => {
                                        return <p key={key}> {onCallDict[key]} </p>
                                    })
                            }
                        </Card>
                    </Col>
                </>

                <Col className="gutter-row site-card-border-less-wrapper" span={7}>
                    <Card bordered={false} title="Point Calculator" >
                        <Form 
                            form={form}
                            name="control-hooks"
                            onFinish={onFinish}
                            autoComplete="off"
                            initialValues={{
                                'totalEngineers': 8,
                                'totalEngineeringDays': 10,
                                'daysLost': 0,
                                'publicHolidays': 0,
                              }}
                        >
                            <Form.Item name="totalEngineers" label="Total Engineers" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="totalEngineeringDays" label="total Engineering Days" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="daysLost" label="Engineer Days Lost" >
                                <Input />
                            </Form.Item>
                            <Form.Item name="publicHolidays" label="Public Holidays" >
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" ghost> Calculate</Button>
                            </Form.Item>
                        </Form>
                        {sprintPoints === undefined || isNaN(sprintPoints) ? "" : alertComponent()}
                        <br />
                        <Descriptions size="small" labelStyle={{fontWeight: "bold"}}>
                        <Descriptions.Item label="Formula"> (Total Engineers * 2 * Total Engineering Days) - (Days Lost * 2) - 
                            (Public Holidays * Total Engineers)</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>
            {/* <Divider orientation="left" ></Divider> */}
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ height: '40vh', margin: "5vh" }}>
                <Col className="gutter-row site-card-border-less-wrapper" span={8} style={{ textAlign: 'center' }}>
                    <Collapse defaultActiveKey={['0']}>
                        <Panel header="Wheel of Fortune" key="1">
                            <Select
                                mode="multiple"
                                allowClear
                                onClear={() => setWheelOfFortuneDropDownOpen(true)}
                                onInputKeyDown={() => setWheelOfFortuneDropDownOpen(true) }
                                // onMouseEnter={() => setWheelOfFortuneDropDownOpen(true) }
                                // onMouseLeave={() => setWheelOfFortuneDropDownOpen(false) }
                                open={wheelOfFortuneDropDownOpen ? true : false}
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Exclude team-members"
                                defaultValue={[]}
                                onChange={handleWheelOptionsSelect}
                                dropdownRender={menu => (
                                    <>
                                        {menu}
                                        <Divider direction="vertical" style={{ margin: '8px 0' }} />
                                        <Space split={<Divider type="vertical" />} style={{ padding: '0 8px 4px' }}>
                                        <Typography.Link onClick={handleRemoveUsersList} style={{ whiteSpace: 'nowrap' }}>
                                                Remove Members
                                            </Typography.Link>
                                            <CloseCircleOutlined  onClick={handleDoNotRemoveUsers} style={{ fontSize: '1.4rem', color: '#08c' }} />
                                        </Space>
                                    </>
                                )}

                            >
                                {wheelOptionsData}
                            </Select>

                           {  showWheelOfFortune ? 
                           <>
                                <Wheel
                                    mustStartSpinning={mustSpin}
                                    prizeNumber={prizeNumber}
                                    data={wheelData}
                                    outerBorderColor={["#f2f2f2"]}
                                    outerBorderWidth={[8]}
                                    innerBorderColor={["#f2f2f2"]}
                                    radiusLineColor={["#dedede"]}
                                    radiusLineWidth={[10]}
                                    textColors={["#ffffff"]}
                                    fontSize={[20]}
                                    perpendicularText={[true]}
                                    spinDuration={spinDuration}
                                    backgroundColors={[
                                        "#F22B35",
                                        "#F99533",
                                        "#24CA69",
                                        "#514E50",
                                        "#46AEFF",
                                        "#9145B7"
                                    ]}
                                    onStopSpinning={() => {
                                        setMustSpin(false);
                                    }}
                                />
                                <Button block type="primary" onClick={handleSpinClick} > SPIN </Button>
                            </> 
                                : ""
                            }
                        </Panel>
                    </Collapse>
                </Col>
                <Col className="gutter-row site-card-border-less-wrapper" span={6}>
                    <Collapse defaultActiveKey={['0']}>
                        <Panel header="Coin Toss" key="1">
                            < CointToss />
                        </Panel>
                    </Collapse>
                </Col>
                <Col className="gutter-row site-card-border-less-wrapper" span={4}>
                    <Collapse defaultActiveKey={['0']}>
                        <Panel header="Sprint Name In Order" key="1">
                            <Search
                                placeholder='search sprint'
                                onSearch={onSprintNameSearch}
                                style={{ marginBottom: "2em" }}
                                enterButton
                                allowClear
                                sx={{  overflowY: 'auto',
                                    maxHeight: '100px' }}
                            />
                            {
                                scrumNameTable.map((element) => {
                                    return scrumNameTable.indexOf(capitalize(element)) >= sprintIndex ? <p key={element}> {element} </p> : ""
                                })
                            }
                        </Panel>
                    </Collapse>
                </Col>
            </Row>
            {/* <Divider orientation="center"></Divider> */}
        </StyledLayout>
    );
}


export default ScrumTools;
