export const prodConfig = {
    Auth: {
      "region": "us-west-2",
      "userPoolId": "us-west-2_69u0SMb4J",
      "userPoolWebClientId": "7oltfmmm88atdldvcv36vtk5k8",
      "identityPoolId": "us-west-2:f135e97c-5b61-499b-8a61-a121199422d6",
      "mandatorySignIn": false,
      oauth: {
        "domain": "dv-live-scrum.auth.us-west-2.amazoncognito.com",
        "scope": [
          "openid"
        ],
        "redirectSignIn": "https://www.scrum.amazonavoc.com/",
        "redirectSignOut": "https://www.scrum.amazonavoc.com/signout/",
        // "redirectSignIn": "https://master.d3dyompjylnzht.amplifyapp.com/",
        // "redirectSignOut": "https://master.d3dyompjylnzht.amplifyapp.com/signout/",
        "responseType": "code"
      }
    },
    API: {
      "endpoints": [{
        "name": "Gamma",
        "endpoint": "https://gamma.api.amazonavoc.com/",
        "region": "us-west-2"
      },
      {
        "name": "Prod",
        "endpoint": "https://prod.api.amazonavoc.com/",
        "region": "us-west-2"
      }
    ]
    }
  }