import React, { createContext, useReducer } from 'react';
  
const globalConextInitialState  =  {
    showPointingPage: false,
    showUserSelectionPage: false,
    showLoginPage: false,
    siteName: undefined,
    userName: undefined,
    userVote: undefined,
    docClient: undefined,
    pendingVoters: [],
    ddbData: [],
}

//gDispatch({type: 'SET_DOC_CLIENT', docClient: docClient });

const globalReducer = (globalState, globalAction) => {
    switch (globalAction.type) {

        case 'SET_DOC_CLIENT':
            return {
                ...globalState,
                docClient: globalAction.docClient,
            }

        case 'SHOW_LOGIN_PAGE':
            return {
                ...globalState,
                showLoginPage: globalAction.showLoginPage,
            }

        case 'SHOW_USER_SELECTION_PAGE':
            return {
                ...globalState,
                showUserSelectionPage: globalAction.showUserSelectionPage,
            }

        case 'SET_REGION_NAME':
            return {
                ...globalState,
                regionName: globalAction.regionName,
            }


        case 'SHOW_POINTING_PAGE':
            return {
                ...globalState,
                showPointingPage: globalAction.showPointingPage,
            };

        case 'SET_USERNAME':
            return {
                ...globalState,
                userName: globalAction.userName,
            };

        case 'SET_VOTE':
            return {
                ...globalState,
                userVote: globalAction.userVote,
            };

        case 'SET_DDB_DATA':
            return {
                ...globalState,
                ddbData: globalAction.ddbData,
            };

        case 'SET_PENDING_VOTERS':
            return {
                ...globalState,
                pendingVoters: globalAction.pendingVoters,
            };
        
        default: return globalState;
    }
}

const GlobalContext = createContext(undefined);

const GlobalContextProvider = ({children}) => {
    const globalContextValue = useReducer(globalReducer, globalConextInitialState );

    return(
        <GlobalContext.Provider value={globalContextValue}>
            {children}
        </GlobalContext.Provider>
    )
}

export {
    GlobalContext,
    GlobalContextProvider,
}