import React, { useState, useContext, useEffect} from 'react';
import styled from 'styled-components';
import { Button,Card,  Divider,Descriptions, Modal, Row, Col,Layout, Spin, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { GlobalContext } from './contexts/ContextProvider';
import { updateDBitem  } from './DbUtils';
import { buttonColors, cardSizes, pointingBase, guestDict, moderatorDict } from './Constants';
import { capitalize } from './utils';

import './App.css';

const StyledHeader = styled.h1 `
  margin: 0 auto; 
  text-align: center;
  font-size: 2.0vw;
  font-weight: 800;
  letter-spacing: 0.2rem;
  color: #e47911; /* Amazon Orange */
  // border: 0.1rem solid #f1f1f1;
`


const StyledDiv = styled.div `
    margin: 0 auto;
    background: #FFFF;
    padding: 16px 16px;
    // border: 0.1rem solid red;
    text-align: center;
    align-items: center;
`

const StyledLayout = styled(Layout) `
    margin: 0 auto;
    background: #FFFF;
    width: 100vw;
    // padding: 8px 0;
    // border: 0.1rem solid red;
    text-align: center;
    // align-items: center;
`

function PointingPage() {
    const [ globalState, globalDispatch] = useContext(GlobalContext);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect( () => {
        if (globalState.userName != 'guest'){
            window.onbeforeunload = function(e){
                e.preventDefault();
                // console.log('(3) Clearning user data in Scrum DB.');
                updateDBitem({
                    userName:globalState.userName,
                    vote: "0",
                    reveal: false,
                    joined: false,
                })
                return false;
              };
        
              window.onunload = function(e){
                  e.preventDefault();
                // console.log('(4) Clearning user data in Scrum DB.');
                updateDBitem({
                    userName:globalState.userName,
                    vote: "0",
                    reveal: false,
                    joined: false,
                })
                return false;
            };
        }

    },[])

    const handleVoteClick = (vote, globalState, globalDispatch) => {
        // upload vote to DDB upon vote click
        globalDispatch({type: 'SET_VOTE', userVote: vote });
        // updateVote(globalState.userName, vote);
        updateDBitem({userName:globalState.userName, vote: vote })
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleModalOkButtonClick = () => {
        setIsModalVisible(false);
        // globalState.ddbData.map( obj => updateRevealStatus(obj.userName)); //
        globalState.ddbData.map( obj => updateDBitem({userName: obj.userName, reveal: true}));
    };
    
    const handleModalCancelButtonClick = () => {
        setIsModalVisible(false);
    };
    

    const handleFinalizeVotesClick = () => {
        // upload vote to DDB upon vote click
        //Add logic to check if anyone logged in and has not voted before finalizing & popup to Moderator
        const notVoted = globalState.ddbData.filter( member => 
            // member.userName !== 'Moderator' && member.userName !== 'Guest' && member.joined === true && member.vote === "0"
            !(member.userName in moderatorDict) && !(member.userName in guestDict) && member.joined === true && member.vote === "0"
        );
        const pendingVoters = notVoted.map( data => data.userName)
        globalDispatch({type: 'SET_PENDING_VOTERS', pendingVoters: pendingVoters})
        
        if ( pendingVoters.length !== 0){
            showModal();
        } else {
            // globalState.ddbData.map( obj => updateRevealStatus(obj.userName));
            globalState.ddbData.map( obj => updateDBitem({userName: obj.userName, reveal: true}));
            setIsModalVisible(false);
        }
    }

    const handleClearVotesClick = () => {
        // upload vote to DDB upon vote click
        console.log(`You selected to clear votes`);
        // globalState.ddbData.map( obj => clearVotes(obj.userName)); //
        globalState.ddbData.map( obj => updateDBitem({userName: obj.userName, vote: '0', reveal: false })); 
    }

    const handleClearDbDataClick = () => {
        // upload vote to DDB upon vote click
        console.log(`You selected to clear user data`);
        // globalState.ddbData.map( obj => clearUserDbData(obj.userName));
        globalState.ddbData.map( obj => updateDBitem({userName: obj.userName, reveal: false, vote: '0', joined: false }));
    }

    const getMemberVote = (member) => {
        if (!member.joined ){
            return "   "
        } else {
            if (member.vote === '0'){
                return 'x';
            } else {
                if (!member.reveal){
                    if( member.userName === globalState.userName){
                        return globalState.ddbData.filter( el => el.userName === member.userName).map( el => el.vote);
                    } else{
                        return <CheckOutlined />;
                    }
                } else {
                    return member.vote ;
                }
            }
        }
    }

    // const getMemberVote = (member) => {
    //     if (member.reveal === true){
    //         if (member.vote !== '0'){
    //             return member.vote ;
    //         } else {
    //             return 'x';
    //         }

    //     } else {
    //         if (member.vote !== '0' && member.userName === globalState.userName){
    //             return globalState.ddbData.filter( el => el.userName === member.userName).map( el => el.vote);
    //         } else {
    //             return 'x';
    //         }
    //     }
    // }

  return (
    <StyledLayout className="site-layout">
            <Row 
                style={{ height: '20vh', marginTop: '5vh', padding: '8px 8px'}}
            >
                <Col span={24} style={{  width: '90vw', margin: '0 auto', textAlign: 'left'}} >
                    {/* Moderator user related buttons */}  
                    {
                        globalState.userName in moderatorDict 
                        ?   
                            <Row style={{margin: '2em'}}>
                                <Space size={'large'} style={{margin: '0 auto'}}> 
                                    <StyledHeader>{globalState.userName }  </StyledHeader>
                                    <Button className="text-center m-2 p-1" size="medium" shape="round" style={{color: "#FFFF", backgroundColor: "#337ab7"}} onClick={() => handleFinalizeVotesClick()} > Finalize Votes</Button>
                                    <Button className="text-center m-2 p-1" size="medium" shape="round" style={{color: "#FFFF", backgroundColor: "#5bc0de"}} onClick={() => handleClearVotesClick()}> Clear Votes</Button>
                                    <Button className="text-center m-2 p-1" size="medium" shape="round" style={{color: "#FFFF", backgroundColor: "#f0ad4e"}} onClick={() => handleClearDbDataClick()}> Clear DB Data</Button>   
                                </Space>
                            </Row>
                        :
                            <Row style={{margin: '2em'}}>
                                <StyledHeader>{capitalize(globalState.userName) }  </StyledHeader>
                            </Row>
                    }
                    <Row>
                        <Col span={24} style={{  margin: '0 auto'}} >
                        <Space split={<Divider type="vertical"  />}>
                        {
                            (globalState.userName in moderatorDict)   || (globalState.userName in guestDict) ? "" :
                            cardSizes.map( (vote, idx) =>  {
                            return <Button 
                                    key={vote}
                                    size="medium"
                                    shape="round"
                                    className= {
                                        (globalState.userName in moderatorDict) || (globalState.userName in guestDict) ? "m-4  pointerOff" : "m-4 "
                                    }
                                    onClick={() => handleVoteClick(vote, globalState, globalDispatch)}
                                    style={{
                                        fontSize: '1.4vw',
                                        fontWeight: '800',
                                        height:'auto',
                                        color: 'white',
                                        background: (globalState.userName in moderatorDict) || (globalState.userName in guestDict) ? '#d9d9d9' :
                                            `${buttonColors[buttonColors.length - idx -1]}`
                                    }}
                                >
                                {vote}
                                </Button>
                            })
                        }
                    </Space>
                        </Col>

                    </Row>
                   
                </Col>
            </Row>
            <Divider orientation="left" plain style={{borderTop: "none"}} ></Divider>
            {/*  Logic:
                    UserName followed by dynamic votes.
                    Make vote buttons grey  if user not voted (vote = 0)
                    Make user button grey if user not logged in
                    Make all button unclickable
                    If user is manager or guest, they dont get voting rights. meaning:
                        (1) Vote cards / buttons are greyed out
                        (2) They log in and can only see others votes
                    // Reveal Logic
                    Moderator gets following buttons 
                        (1) reveal all votes
                        (2) reset all votes
                        (3) on exit, reset all DDB data
                */}
                <div className="site-card-wrapper">
                    <Row gutter={16}>
                        {
                            globalState.ddbData.length !== 0 ?
                                globalState.ddbData.filter( obj => !(obj.userName in moderatorDict) &&  !(obj.userName in guestDict)).map( member =>  {
                                    return  <Col key={`${member.userName}-col}`} xs={6} sm={4}  lg={3} xl={3} > 
                                            <Card bordered={false}
                                                style={{border: member.joined !== false ? "0.1rem solid #f0ad4e": "0.1rem solid #BEBEBE",
                                                        padding: "0.5em", margin: "0.5em",  borderRadius: "25px"}}
                                            >
                                            
                                                <Button  
                                                    key={member.userName}
                                                    size="large"
                                                    shape="round"
                                                    type="primary"
                                                    block
                                                    // ghost
                                                    style={{
                                                        fontSize: '0.8vw',
                                                        fontWeight: '800',
                                                        height:'auto',
                                                        // width: 'auto',
                                                        pointerEvents: 'none',
                                                        border:'none',
                                                        borderRadius: "10px",
                                                        padding: "1em",
                                                        // margin: "0.2em",
                                                        background: member.joined !== false ? "#5bc0de": "#D3D3D3"
                                                    }}
                                                    disabled={member.joined !== false ? false: true}
                                                >
                                                    {capitalize(member.userName)} 
                                                </Button>

                                                <Divider orientation="left" key={`divdr-${member.userName}`}></Divider>

                                            
                                                <Button 
                                                    key={`${member.userName}-vote-${globalState.userVote}`}
                                                    size="large"
                                                    shape="round"
                                                    type="primary"
                                                    block
                                                    // ghost
                                                    style={{
                                                        fontSize: '1.0vw',
                                                        fontWeight: '800',
                                                        height:'auto',
                                                        pointerEvents: 'none',
                                                        border:'none',
                                                        borderRadius: "10px",
                                                        padding: "0.2em",
                                                        margin: "0.2em",
                                                        background: member.joined !== false && member.vote !== '0'? "#5cb85c": "#D3D3D3" 
                                                    }}
                                                    disabled={member.joined !== false ? false: true}
                                                > 
                                                    { getMemberVote(member)}

                                                </Button>
                                            </Card>
                                        </Col> 
                                })
                            : 
                            <StyledDiv>
                                <Spin size={'large'} />
                            </StyledDiv>
                        }
                    </Row>
                    {/* For members who  won't / cant vote */}
                    <Row gutter={16}>
                        {
                            globalState.ddbData.length !== 0 ?
                                globalState.ddbData.filter( obj => (obj.userName in moderatorDict) || (obj.userName in guestDict)).map( member =>  {
                                    return  <Col key={`${member.userName}-col}`} xs={6} sm={4}  lg={3} xl={3} > 
                                            <Card bordered={false}
                                                style={{border: member.joined !== false ? "0.1rem solid #f0ad4e": "0.1rem solid #BEBEBE",
                                                        padding: "0.5em", margin: "0.5em",  borderRadius: "25px"}}
                                            >
                                            
                                                <Button  
                                                    key={member.userName}
                                                    size="large"
                                                    shape="round"
                                                    type="primary"
                                                    block
                                                    // ghost
                                                    style={{
                                                        fontSize: '0.8vw',
                                                        fontWeight: '800',
                                                        height:'auto',
                                                        pointerEvents: 'none',
                                                        border:'none',
                                                        borderRadius: "10px",
                                                        padding: "1em",
                                                        // margin: "1em",
                                                        background: member.joined !== false ? "#5bc0de": "#D3D3D3"
                                                    }}
                                                    disabled={member.joined !== false ? false: true}
                                                >
                                                    {capitalize(member.userName)} 
                                                </Button>

                                                {/* <Divider orientation="left" key={`divdr-${member.userName}`}></Divider> */}
                                                
                                            </Card>
                                        </Col> 
                                })
                            : 
                            <StyledDiv>
                                <Spin size={'large'} />
                            </StyledDiv>
                        }
                    </Row>


                </div>


            <Divider orientation="left" plain></Divider>
            
            <Row 
                className="gutter-row"
                style={{ marginTop: '5vh', height: '20vh', margin: '8px 8px', padding: '8px 8px'}}
            >
                <Col className="gutter-row" span={24}>
                    <Descriptions
                        title="New Pointing Base"
                        // bordered
                        size={'small'}
                        span={2}
                        column={{ md: 3 }}
                        style={{
                            fontSize: '1.0vw',
                            fontWeight: '400',
                            height:'auto'
                        }}
                        >
                        {
                            Object.keys(pointingBase).map( points => {
                                return <Descriptions.Item label={points} key={points.toString()}>{pointingBase[points]}</Descriptions.Item>
                            })
                        }
                    </Descriptions>
                </Col>
            </Row>
            { isModalVisible 
                        ? 
                            <Modal 
                                title="Warning: Following logged in members have not voted"  
                                visible={isModalVisible} 
                                // onOk={handleModalOkButtonClick} 
                                onCancel={handleModalCancelButtonClick}
                                footer={[
                                    <Button ghost type="danger" key="1" onClick={handleModalCancelButtonClick}>Cancel</Button>,
                                    <Button ghost type="primary" key="2" onClick={handleModalOkButtonClick}>Reveal</Button>
                                ]}
                            >
                                {globalState.pendingVoters.map( (member, idx) => <p key={`${member}-${idx}`}> { capitalize(member) } </p>)}
                            </Modal>

                        : ""
                    }
        </StyledLayout>
  );
}

export default PointingPage;
