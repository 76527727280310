import React from "react";
import {Auth} from "aws-amplify";
import styled from 'styled-components';
import { Button, Card, Row, Col, Space, Divider} from 'antd';

const StyledHeader = styled.h1 `
  margin: 0 auto; 
  margin-top: 2.0vw;
  margin-bottom: 2.0vw;
  text-align: center;
  font-size: 2.4vw;
  font-weight: 800;
  letter-spacing: 0.2rem;
  color: #e47911; /* Amazon Orange */
  // border: 0.1rem solid #f1f1f1;
`

export const Login = () => {
    //const { dispatch } = React.useContext(AuthContext);
    const signIn = async (e) => {
        e.preventDefault();
        try {
            console.log("Logging in via AWS-Federate-via-Midway");
            await Auth.federatedSignIn({provider: "AWS-Federate-via-Midway"});
            Auth.currentSession().then(data => console.log(data.idToken.jwtToken));
        } catch (error) {
            console.log('error signing in', error);
        }
    }
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col 
        className="gutter-row text-center"
        span={24}
        style={{  width: '97vw', height: '90vh', background: "#fff" }}
        >
        <Card bordered={false}
            style={{ height: '90vh', width: '90vw', margin: '0 auto', marginTop: '10vh'}} 
            title={<StyledHeader> Please Login </StyledHeader>}
        >
            <StyledHeader>
            <Space split={<Divider type="vertical" style={{ margin: '0 auto'}} />}>
            <Button 
                    size="large"
                    type="primary"
                    shape="round"
                    className={`m-4`}
                    onClick={signIn}
                >
                Login
                </Button>

            </Space>
        
            </StyledHeader>
        </Card>
        </Col>
    </Row>
  );
};

export default Login;
