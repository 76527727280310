// Pointing tool manager page

import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';

import SelectUser from './UserSelector';
import RefreshDb  from './RefreshDbData.js';
import { GlobalContext } from './contexts/ContextProvider';
import { Row } from 'antd';

import './App.css';

import SelectRegion from './RegionSelector';
import PointingPage from './PointingTool';

function PointingToolManger() {
  /** this functon */
  const [ globalState, globalDispatch] = useContext(GlobalContext);
  

  return (

    <Row>
     
      < RefreshDb />
      {
        globalState.regionName === undefined 
        ? 
          <SelectRegion /> 
        : !globalState.showPointingPage 
          ?
            < SelectUser />
          :
            <PointingPage />
      }
    
    </Row>
  );
}

export default PointingToolManger;
