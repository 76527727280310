//  Refresh DB Data Component

import React, { useState, useContext, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';

// import { scanDBitems } from './DbUtils';
import { DbPollPeriod,  } from './Constants';
import { GlobalContext } from './contexts/ContextProvider';

function useInterval(callback, delay) {
  const savedCallback = useRef();


  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function RefreshDb() {
  const [ globalState, globalDispatch] = useContext(GlobalContext);
  let [count, setCount] = useState(0);

  const scanDBitems = async () => {
    const path = "scrum/scan";
    console.log(`Executing API.get on https://prod.api.amazonavoc.com/${path}`)
  
    var handleError = function (err) {
      console.warn(err);
      return new Response(JSON.stringify({
          code: 400,
          message: 'Error executing scanDBitems function'
      }));
    };
  
    const response = await (API.get(
      'Prod', 
      path, {
        headers: {
          'Cache-Control': 'max-age=87000',
          'Content-type': 'application/json',
        },
        queryStringParameters: {
          action : "scan_records",
        }
      }).catch(handleError));
  
      const data = await response;
      console.log(data) //DEBUG AAM
      globalDispatch({type: 'SET_DDB_DATA', ddbData: data });
      return data;
  }



  useInterval(() => {
    scanDBitems();
    setCount(count + 1);
  }, DbPollPeriod);

  return (
    // Dummy return
    <> </>
  )

}

export default RefreshDb;