//  Region Selector Component
import React, { useContext } from 'react';
import styled from 'styled-components';
import { GlobalContext } from './contexts/ContextProvider';
import { Card, Button, Row, Col, Space, Divider } from 'antd';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const StyledHeader = styled.h1 `
  margin: 0 auto; 
  margin-top: 2.0vw;
  margin-bottom: 2.0vw;
  text-align: center;
  font-size: 2.0vw;
  font-weight: 800;
  letter-spacing: 0.2rem;
  color: #e47911; /* Amazon Orange */
  // border: 0.1rem solid #f1f1f1;
`


function SelectRegion() {
  const [ globalState, globalDispatch] = useContext(GlobalContext);
  
  const handleRegionClick = (region, globalState, globalDispatch) => {
    globalDispatch({type: 'SHOW_LOGIN_PAGE', showLoginPage: true });
    globalDispatch({type: 'SET_REGION_NAME', regionName: region });
  }

  const regionList = [ 'EU', 'NA'];

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col 
          className="gutter-row text-center"
          span={24}
          style={{  width: '97vw', height: '90vh', background: "#fff" }}
        >
          <Card bordered={false}
            style={{ height: '90vh', width: '90vw', margin: '0 auto', marginTop: '10vh'}} 
            title={<StyledHeader> What's your Region ? </StyledHeader>}
          >
            <StyledHeader>
            <Space split={<Divider type="vertical" style={{ margin: '0 auto'}} />}>
            { 
              regionList.map( (region, idx) =>  {
              return <Button 
                    key={`${region}-${idx}`}
                    size="large"
                    shape="round"
                    type= {`${region === 'EU' ? "" : 'primary'}`}
                    disabled={region === 'EU' ? true: false}
                    className={`m-4`}
                    onClick={() => handleRegionClick(region, globalState, globalDispatch)}
                >
                {region}
                </Button>
              })
            }

            </Space>
           
            </StyledHeader>
          </Card>
        </Col>
    </Row>
  );
}

export default SelectRegion;
