import React, { createContext, useReducer } from 'react';

const AuthReducer = (AuthState, AuthAction) => {
  // console.log("AuthAction.payload.user=>" + AuthAction.payload.user); //DEBUG AAM
  // console.log("AuthAction.payload.token=>" + AuthAction.payload.token); //DEBUG AAM
  switch (AuthAction.type) {
    case "LOGIN":
      localStorage.setItem("user", AuthAction.payload.user);
      localStorage.setItem("token", AuthAction.payload.token);
      return {
        ...AuthState,
        isAuthenticated: true,
        user: AuthAction.payload.user,
        token: AuthAction.payload.token
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...AuthState,
        isAuthenticated: false,
        user: AuthAction.payload.user,
        token: AuthAction.payload.token
      };
    default:
      return AuthState;
  }
};

const AuthInitialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

  // create Auth context
const AuthContext = createContext(null);

const AuthContextProvider = ({children}) => {
  const AuthContextValue = useReducer(AuthReducer, AuthInitialState );

  return(
      <AuthContext.Provider value={AuthContextValue}>
          {children}
      </AuthContext.Provider>
  )
}

export {
  AuthContext,
  AuthContextProvider,
}